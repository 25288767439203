export const CREATE_CUSTOMER_INITIAL = 'CREATE_CUSTOMER_INITIAL';
export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE';
export const EDIT_CUSTOMER_INITIAL = 'EDIT_CUSTOMER_INITIAL';
export const EDIT_CUSTOMER_REQUEST = 'EDIT_CUSTOMER_REQUEST';
export const EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS';
export const EDIT_CUSTOMER_FAILURE = 'EDIT_CUSTOMER_FAILURE';
export const DELETE_CUSTOMER_INITIAL = 'DELETE_CUSTOMER_INITIAL';
export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE';
export const FETCH_CUSTOMERS_REQUEST = 'FETCH_CUSTOMERS_REQUEST';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = 'FETCH_CUSTOMERS_FAILURE';
export const CREATE_INVITE_FAILURE = 'CREATE_INVITE_FAILURE';
export const CREATE_INVITE_SUCCESS = 'CREATE_INVITE_SUCCESS';


function createCustomerInitial() {
  return {
    type: CREATE_CUSTOMER_INITIAL,
    isFetching: false,
  };
}

function requestCreateCustomer(customer) {
  return {
    type: CREATE_CUSTOMER_REQUEST,
    isFetching: true,
    customer,
  };
}

function createCustomerSuccess(customer) {
  return {
    type: CREATE_CUSTOMER_SUCCESS,
    isFetching: false,
    customer,
  };
}

function createCustomerError(message) {
  return {
    type: CREATE_CUSTOMER_FAILURE,
    isFetching: false,
    message,
  };
}

function editCustomerInitial() {
  return {
    type: EDIT_CUSTOMER_INITIAL,
    isFetching: false,
  };
}

function requestEditCustomer(customer) {
  return {
    type: EDIT_CUSTOMER_REQUEST,
    isFetching: true,
    customer,
  };
}

function editCustomerSuccess(customer) {
  return {
    type: EDIT_CUSTOMER_SUCCESS,
    isFetching: false,
    customer,
  };
}

function editCustomerError(message) {
  return {
    type: EDIT_CUSTOMER_FAILURE,
    isFetching: false,
    message,
  };
}

function deleteCustomerInitial() {
  return {
    type: DELETE_CUSTOMER_INITIAL,
    isFetching: false,
  };
}

function requestDeleteCustomer(customerId) {
  return {
    type: DELETE_CUSTOMER_REQUEST,
    isFetching: true,
    customerId,
  };
}

function deleteCustomerSuccess(payload) {
  return {
    type: DELETE_CUSTOMER_SUCCESS,
    isFetching: false,
    payload,
  };
}

function deleteCustomerError(message) {
  return {
    type: DELETE_CUSTOMER_FAILURE,
    isFetching: false,
    message,
  };
}

function requestFetchCustomers() {
  return {
    type: FETCH_CUSTOMERS_REQUEST,
    isFetching: true,
  };
}

function fetchCustomersSuccess(customers) {
  return {
    type: FETCH_CUSTOMERS_SUCCESS,
    isFetching: false,
    customers,
  };
}

function fetchCustomersError(message) {
  return {
    type: FETCH_CUSTOMERS_FAILURE,
    isFetching: false,
    message,
  };
}


function createInviteError(message) {
  return {
    type: CREATE_INVITE_FAILURE,
    message,
  };
}

function createInviteSuccess(message) {
  return {
    type: CREATE_INVITE_SUCCESS,
    message,
  };
}





export function createCustomer(customerData) {
  const config = {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
    },
    body: JSON.stringify(customerData),
  };

  return dispatch => {
    dispatch(requestCreateCustomer(customerData));
    return fetch(process.env.REACT_APP_baseURLApi+'/api/protected/admin/customers', config)
      .then(response => response.json().then(customer => ({ customer, response })))
      .then(({ customer, response }) => {
        if (!response.ok) {
          // If there was a problem, we want to dispatch the error condition
          dispatch(createCustomerError(customer.message));
          return Promise.reject(customer);
        }
        // Dispatch the success action
        dispatch(createCustomerSuccess(customer));
        setTimeout(() => {
          dispatch(createCustomerInitial());
        }, 5000);
        return Promise.resolve(customer);
      })
      .catch(err => console.error('Error: ', err));
  }
}

export function editCustomer(customerData) {
  const config = {
    method: 'put',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
    },
    body: JSON.stringify(customerData),
  };

  const customer_id = customerData.id;

  return dispatch => {
    dispatch(requestEditCustomer(customerData));
    return fetch(process.env.REACT_APP_baseURLApi+'/api/protected/admin/customers/'+customer_id, config)
      .then(response => response.json().then(customer => ({ customer, response })))
      .then(({ customer, response }) => {
        if (!response.ok) {
          // If there was a problem, we want to dispatch the error condition
          dispatch(editCustomerError(customer.message));
          return Promise.reject(customer);
        }
        // Dispatch the success action
        dispatch(editCustomerSuccess(customer));
        setTimeout(() => {
          dispatch(editCustomerInitial());
        }, 5000);
        return Promise.resolve(customer);
      })
      .catch(err => console.error('Error: ', err));
  }
}

export function deleteCustomer(customerId) {
  const config = {
    method: 'delete',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
    },
  };

  return dispatch => {
    dispatch(requestDeleteCustomer(customerId));
    return fetch(process.env.REACT_APP_baseURLApi+'/api/protected/admin/customers/'+customerId, config)
      .then(response => response.json().then(response => ({ response })))
      .then(({response }) => {
        if (!response.id) {
          // If there was a problem, we want to dispatch the error condition
          dispatch(deleteCustomerError(response.message));
          return Promise.reject(response.message);
        }
        // Dispatch the success action
        dispatch(deleteCustomerSuccess(response));
        setTimeout(() => {
          dispatch(deleteCustomerInitial());
        }, 5000);
        return Promise.resolve(response.id);
      })
      .catch(err => console.error('Error: ', err));
  }
}

export function fetchCustomers() {
  const config = {
    method: 'get',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
    },
  };

  return dispatch => {
    dispatch(requestFetchCustomers());
    return fetch(process.env.REACT_APP_baseURLApi+'/api/protected/admin/customers', config)
      .then(response =>
        response.json().then(responseJson => ({
          customers: responseJson.customers
        }))
      )
      .then(({ customers }) => {
        if (!customers) {
          // If there was a problem, we want to dispatch the error condition
          dispatch(fetchCustomersError(customers.message));
          return Promise.reject(customers);
        }
        // Dispatch the success action
        dispatch(fetchCustomersSuccess(customers));
        return Promise.resolve(customers);
      })
      .catch(err => console.error('Error: ', err));
  };
}

export function resendInviteCustomer(customerId) {
  const config = {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
    },
    body: JSON.stringify({"id":customerId}),
  };

  return dispatch => {
    return fetch(process.env.REACT_APP_baseURLApi+'/api/protected/admin/customers/invite', config)
      .then(response => response.json())
      .then((result) => {
        if (!result.message) {
          // If there was a problem, we want to dispatch the error condition
          dispatch(createInviteError(result));
          return Promise.reject(result);
        }
        // Dispatch the success action
        dispatch(createInviteSuccess(result.message));
        return Promise.resolve(result);
      })
      .catch(err => console.error('Error: ', err));
  }
}
