import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Table,
  Breadcrumb,
  BreadcrumbItem,
  Input,
  Badge,
  Button
} from 'reactstrap';
import s from './CustomerList.module.scss';
import Widget from '../../../components/Widget/Widget';
import { fetchCustomers, deleteCustomer, resendInviteCustomer } from '../../../actions/customer';
import { fetchCompanies } from '../../../actions/company';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CustomerList extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    customers: PropTypes.array, // eslint-disable-line
    isFetching: PropTypes.bool,
    message: PropTypes.string,
    errorMessage: PropTypes.string,
    companies: PropTypes.array, // eslint-disable-line
  };

  static defaultProps = {
    isFetching: false,
    customers: [],
    message: null,
    errorMessage:null,
    companies: [],
  };

  static meta = {
    title: 'Customers list',
    description: 'About description',
  };

  state = {
    companies:[],
    searchQuery: '',
    companyQuery: '',
    companyId:0,
  }

  componentDidMount() {
    const company = localStorage.getItem('company');
    if (company !== null) {
      this.setState({companyQuery: company});
    }
    this.props.dispatch(fetchCustomers());
    this.props.dispatch(fetchCompanies());
  }

  formatDate = (str) => {
    return str.replace(/,.*$/,"")
  }

  deleteCustomerData = (customerId) => {
    this.props
    .dispatch(
      deleteCustomer(customerId),
    ).then(()=>{
      this.props.errorMessage && (toast.error(this.props.errorMessage))
      this.props.message && (toast.success(this.props.message))
      
    });
  }

  resendInvite = (customerId) => {
    this.props
    .dispatch(
      resendInviteCustomer(customerId),
    ).then(()=>{
      this.props.errorMessage && (toast.error(this.props.errorMessage))
      this.props.message && (toast.success(this.props.message))
      
    });
  }

  searchCustomer = (e) => {
    this.setState({searchQuery: e.target.value});
    e.preventDefault();
  }

  filterByCompany = (e) => {
    e.preventDefault()
    if(e.target.value){
      const val = (e.target.value).split("+");
      localStorage.setItem('company', val[1]?val[1]:"");
      this.setState({companyQuery: val[1]});
      this.setState({companyId: val[0]});
    }
    else{
      localStorage.setItem('company', "");
      this.setState({companyQuery:""});
      this.setState({companyId: ""});
    }
    
  }

  getCompanyData=()=>{
    window.open(`${process.env.REACT_APP_baseURLApi}/api/user/download-invited-users/${this.state.companyId}`, '_blank', 'noreferrer')
  }

  render() {
    return (
      <div className={s.root}>
        <Breadcrumb>
          <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
          <BreadcrumbItem active>Users</BreadcrumbItem>
        </Breadcrumb>
        <h1>Users</h1>
        <Widget
          className="pb-0"
          title={
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-between align-items-center">
                <Input className={s.searchUser} type="search" onChange={this.searchCustomer} name="search" id="customerSearch" placeholder="search by email"/>
                <select className={s.selectCompany} name="selectCompany" value={`${this.state.companyId}+${this.state.companyQuery}`} onChange={this.filterByCompany}>
                  <option value="">Select company</option>
                  {this.props.companies.map(function (company) {
                    return <option key={company.id} value={`${company.id}+${company.name}`}>{company.name}</option>;
                  })}
                </select>
                 <Button disabled={(this.state.companyQuery==='' || this.state.searchQuery!=='')} className={s.exportButton} onClick={this.getCompanyData} >Export Company Data</Button>
              </div>
              <div>
                <Link to={{ pathname: '/app/user/new' }}  className="btn btn-sm btn-info">
                  Create new
                </Link>
              </div>
            </div>
          }
        >
          <div className="widget-table-overflow">
            <Table striped>
              <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Company</th>
                <th>Role</th>
                <th>Status</th>
                <th>Subscription Type</th>
                <th>Invite Email</th>
                <th>Options</th>
              </tr>
              </thead>
              <tbody>
              { this.props.customers && 
                this.props.customers.filter(customer => customer.emailId.includes(this.state.searchQuery) 
                                                        && customer.company 
                                                        && (this.state.companyQuery?customer.company.name === this.state.companyQuery:true)
                                            )
                .map(customer => (
                  <tr key={customer.id}>
                    <td>{customer.firstName?customer.firstName:'-'}</td>
                    <td>{customer.lastName?customer.lastName:'-'}</td>
                    <td>{customer.emailId?customer.emailId:'-'}</td>
                    <td>{customer.company.name ? customer.company.name : '-'}</td>
                    <td>{customer.role.roleName ? customer.role.roleName:'-'}</td>
                    <td>{customer.isActive?<Badge color="success">Active</Badge> : <Badge color="danger">Inactive</Badge>}</td>  
                    <td>{customer.company.subscriptionType ? customer.company.subscriptionType : '-'}</td>
                    <td>
                      <div>
                        {{ 
                          0: <div>
                              <Badge color="default">Not Sent</Badge>
                              <Button color="inverse" className="width-100 mr-xs" onClick={() => { this.resendInvite(customer.id)}}>
                                <i className="glyphicon glyphicon-repeat text-info mr-xs mb-xs" />
                              </Button>
                            </div>,
                          1: <div>
                              <Badge color="info">Sent</Badge>
                              <Button color="inverse" className="width-100 mr-xs" onClick={() => { this.resendInvite(customer.id)}}>
                                <i className="glyphicon glyphicon-repeat text-info mr-xs mb-xs" />
                              </Button>
                            </div>,
                          2: <Badge color="success">Accepted</Badge>
                        }[customer.inviteEmailStatus] || <div>Undefined</div>}
                        </div>
                    </td>  
                    <td>
                      <Link to={{ pathname: '/app/user/new', customerDetails: customer}} className="btn btn-sm btn-inverse">
                        Edit
                      </Link>
                      <div className="btn btn-sm btn-danger" onClick={() => { this.deleteCustomerData(customer.id)}}>
                        <i className="glyphicon glyphicon-remove" />
                      </div>
                    </td>
                  </tr>
                ))
              }
              { this.props.isFetching && (
                <tr>
                  <td colSpan="100">Loading...</td>
                </tr>
              )}
              </tbody>
            </Table>
          </div>
        </Widget>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.customer.isFetching,
    customers: state.customer.customers,
    message: state.customer.message,
    errorMessage: state.customer.errorMessage,
    companies: state.company.companies,
  };
}

export default connect(mapStateToProps)(CustomerList);
