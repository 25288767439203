export const CREATE_COMPANY_INITIAL = 'CREATE_COMPANY_INITIAL';
export const CREATE_COMPANY_REQUEST = 'CREATE_COMPANY_REQUEST';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAILURE = 'CREATE_COMPANY_FAILURE';
export const EDIT_COMPANY_INITIAL = 'EDIT_COMPANY_INITIAL';
export const EDIT_COMPANY_REQUEST = 'EDIT_COMPANY_REQUEST';
export const EDIT_COMPANY_SUCCESS = 'EDIT_COMPANY_SUCCESS';
export const EDIT_COMPANY_FAILURE = 'EDIT_COMPANY_FAILURE';
export const DELETE_COMPANY_INITIAL = 'DELETE_COMPANY_INITIAL';
export const DELETE_COMPANY_REQUEST = 'DELETE_COMPANY_REQUEST';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAILURE = 'DELETE_COMPANY_FAILURE';
export const FETCH_COMPANIES_REQUEST = 'FETCH_COMPANIES_REQUEST';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAILURE = 'FETCH_COMPANIES_FAILURE';
export const REQUEST_UPLOAD_USERS = 'REQUEST_UPLOAD_USERS';
export const REQUEST_UPLOAD_USERS_SUCCESS = 'REQUEST_UPLOAD_USERS_SUCCESS';
export const REQUEST_UPLOAD_USERS_FAILURE = 'REQUEST_UPLOAD_USERS_FAILURE';

function createCompanyInitial() {
  return {
    type: CREATE_COMPANY_INITIAL,
    isFetching: false,
  };
}

function requestCreateCompany(company) {
  return {
    type: CREATE_COMPANY_REQUEST,
    isFetching: true,
    company,
  };
}

function createCompanySuccess(company) {
  return {
    type: CREATE_COMPANY_SUCCESS,
    isFetching: false,
    company,
  };
}

function createCompanyError(message) {
  return {
    type: CREATE_COMPANY_FAILURE,
    isFetching: false,
    message,
  };
}

function editCompanyInitial() {
  return {
    type: EDIT_COMPANY_INITIAL,
    isFetching: false,
  };
}

function requestEditCompany(company) {
  return {
    type: EDIT_COMPANY_REQUEST,
    isFetching: true,
    company,
  };
}

function editCompanySuccess(company) {
  return {
    type: EDIT_COMPANY_SUCCESS,
    isFetching: false,
    company,
  };
}

function editCompanyError(message) {
  return {
    type: EDIT_COMPANY_FAILURE,
    isFetching: false,
    message,
  };
}

function deleteCompanyInitial() {
  return {
    type: DELETE_COMPANY_INITIAL,
    isFetching: false,
  };
}

function requestDeleteCompany(companyId) {
  return {
    type: DELETE_COMPANY_REQUEST,
    isFetching: true,
    companyId,
  };
}

function deleteCompanySuccess(payload) {
  return {
    type: DELETE_COMPANY_SUCCESS,
    isFetching: false,
    payload,
  };
}

function deleteCompanyError(message) {
  return {
    type: DELETE_COMPANY_FAILURE,
    isFetching: false,
    message,
  };
}

function requestFetchCompanies() {
  return {
    type: FETCH_COMPANIES_REQUEST,
    isFetching: true,
  };
}

function fetchCompaniesSuccess(companies) {
  return {
    type: FETCH_COMPANIES_SUCCESS,
    isFetching: false,
    companies,
  };
}

function fetchCompaniesError(message) {
  return {
    type: FETCH_COMPANIES_FAILURE,
    isFetching: false,
    message,
  };
}


function uploadUsersList(){
  return {
    type: REQUEST_UPLOAD_USERS,
    isFetching: true,
  };
}

function uploadUsersListSuccess(message){
  return {
    type: REQUEST_UPLOAD_USERS_SUCCESS,
    isFetching: false,
    message,
  };
}

function uploadUsersListError(message){
  return {
    type: REQUEST_UPLOAD_USERS_FAILURE,
    isFetching: false,
    message,
  };
}

export function createCompany(companyData) {
  const config = {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
    },
    body: JSON.stringify(companyData),
  };

  return dispatch => {
    dispatch(requestCreateCompany(companyData));
    return fetch(process.env.REACT_APP_baseURLApi+'/api/protected/admin/companies', config)
      .then(response => response.json().then(company => ({ company, response })))
      .then(({ company, response }) => {
        if (!response.ok) {
          // If there was a problem, we want to dispatch the error condition
          dispatch(createCompanyError(company.message));
          return Promise.reject(company);
        }
        // Dispatch the success action
        dispatch(createCompanySuccess(company));
        setTimeout(() => {
          dispatch(createCompanyInitial());
        }, 5000);
        return Promise.resolve(company);
      })
      .catch(err => console.error('Error: ', err));
  }
}

export function editCompany(companyData) {
  const config = {
    method: 'put',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
    },
    body: JSON.stringify(companyData),
  };

  const company_id = companyData.id;

  return dispatch => {
    dispatch(requestEditCompany(companyData));
    return fetch(process.env.REACT_APP_baseURLApi+'/api/protected/admin/companies/'+company_id, config)
      .then(response => response.json().then(company => ({ company, response })))
      .then(({ company, response }) => {
        if (!response.ok) {
          // If there was a problem, we want to dispatch the error condition
          dispatch(editCompanyError(company.message));
          return Promise.reject(company);
        }
        // Dispatch the success action
        dispatch(editCompanySuccess(company));
        setTimeout(() => {
          dispatch(editCompanyInitial());
        }, 5000);
        return Promise.resolve(company);
      })
      .catch(err => console.error('Error: ', err));
  }
}

export function deleteCompany(companyId) {
  const config = {
    method: 'delete',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
    },
  };

  return dispatch => {
    dispatch(requestDeleteCompany(companyId));
    return fetch(process.env.REACT_APP_baseURLApi+'/api/protected/admin/companies/'+companyId, config)
      .then(response => response.json().then(response => ({ response })))
      .then(({response }) => {
        if (!response.id) {
          // If there was a problem, we want to dispatch the error condition
          dispatch(deleteCompanyError(response.message));
          return Promise.reject(response.message);
        }
        // Dispatch the success action
        dispatch(deleteCompanySuccess(response));
        setTimeout(() => {
          dispatch(deleteCompanyInitial());
        }, 5000);
        return Promise.resolve(response.id);
      })
      .catch(err => console.error('Error: ', err));
  }
}

export function fetchCompanies() {
  const config = {
    method: 'get',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
    },
  };

  return dispatch => {
    dispatch(requestFetchCompanies());
    return fetch(process.env.REACT_APP_baseURLApi+'/api/protected/admin/companies', config)
      .then(response =>
        response.json().then(responseJson => ({
          companies: responseJson.companies
        }))
      )
      .then(({ companies }) => {
        if (!companies) {
          // If there was a problem, we want to dispatch the error condition
          dispatch(fetchCompaniesError(companies.message));
          return Promise.reject(companies);
        }
        // Dispatch the success action
        dispatch(fetchCompaniesSuccess(companies));
        return Promise.resolve(companies);
      })
      .catch(err => console.error('Error: ', err));
  };
}


export function uploadUsers(companyId,file) {
  const formData = new FormData();
  formData.append('companyId', companyId);
  formData.append('file', file);
  const config = {
    method: 'post',
    headers: {
      'Authorization': localStorage.getItem('token')
    },
    body: formData,
  };

  return dispatch => {
    dispatch(uploadUsersList());
    return fetch(process.env.REACT_APP_baseURLApi+'/api/protected/admin/customers/upload', config)
      .then(response => response.json().then(result => ({ result, response })))
      .then(({ result, response }) => {
        if (!response.ok) {
          // If there was a problem, we want to dispatch the error condition
          dispatch(uploadUsersListError(result.message));
          return Promise.reject(result);
        }
        // Dispatch the success action
        dispatch(uploadUsersListSuccess());
      })
      .catch(err => console.error('Error: ', err));
  }
}
