import React from 'react';
import { Switch, Route, withRouter } from 'react-router';

import CompanyList from './list/CompanyList';
import CompanyNew from './new/CompanyNew';
import CompanyDetails from './details/CompanyDetails';

class Companies extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/app/companies" exact component={CompanyList} />
        <Route path="/app/company/new" exact component={CompanyNew} />
        <Route path="/app/company" exact component={CompanyDetails} />
      </Switch>
    );
  }
}

export default withRouter(Companies);
