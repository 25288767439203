import S3 from 'react-aws-s3';

class AWSS3 {

    config = {
        bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
        dirName: process.env.REACT_APP_S3_DIR,
        region: process.env.REACT_APP_S3_REGION,
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET,
        s3Url: process.env.REACT_APP_S3_BUCKET_URL, 
    }

    ReactS3Client = new S3(this.config);

    uploadFileToS3 = (file, newFileName) => {
        return this.ReactS3Client
            .uploadFile(file, newFileName)
        /**
         * {
         *   Response: {
         *     bucket: "myBucket",
         *     key: "image/test-image.jpg",
         *     location: "https://myBucket.s3.amazonaws.com/media/test-file.jpg"
         *   }
         * }
         */
    };

    deletedFileFromS3 = filename => {
        this.ReactS3Client
        .deleteFile(filename)
        .then(response => console.log(response))
        .catch(err => console.error(err))

        /**
         * {
         *   Response: {
         *      ok: true,
                 status: 204,
                message: 'File deleted',
                fileName: 'hello-world.docx'
        *   }
        * }
        */
    };

}

const singletonAWSS3Instance = new AWSS3();
  
Object.freeze(singletonAWSS3Instance); //immutable

export default singletonAWSS3Instance;
