import React, {Component} from 'react';
import {
  Row,
  Col,
} from 'reactstrap';

import Widget from '../../components/Widget/Widget';

import singletonAWSS3Instance from '../../utils/s3uploader';
import s from './Files.module.scss';

class Files extends Component {

  
  state = {
    fileUploadProgress : false,
    uploadedURL : null
  }

  componentDidMount() {
  }

  uploadFile(event){
    event.preventDefault();
    var file = event.target.files[0];
    event.target.value = '';
    this.setState({fileUploadProgress : true});
    singletonAWSS3Instance.uploadFileToS3(file,file.name)
    .then(data => {
      // alert(data.location);
      this.setState({fileUploadProgress : false, uploadedURL : data.location});
    })
    .catch(err => {
      alert("Something went wrong!");
      console.error(err);
      this.setState({fileUploadProgress : false});
    });
  }

  render() {
    return (
      <div className={s.root}>
                <Row>
          <Col>
            <Widget
              title={<h6><span className="fw-semi-bold">AWS S3 upload</span>
              </h6>} close collapse
            >
              <Row>
                <Col>
                  <h4><span className="fw-semi-bold">File</span> upload
                    <i className="glyphicon glyphicon-open-file text-success mr-xs mb-xs" />
                  </h4>
                  <p className="fs-mini text-muted">
                    Upload a file and get the link
                  </p>
                  {this.state.fileUploadProgress && (
                    <p>Upload in progress ... </p>
                  )}
                  {!this.state.fileUploadProgress && this.state.uploadedURL && (
                    <p>Uploaded URL is {this.state.uploadedURL} </p>
                  )}
                  <div className="mb-sm">
                    <input type="file" color="default" className="width-100 mr-xs" onChange={e=>this.uploadFile(e)}/>
                  </div>
                </Col>
              </Row>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Files;
