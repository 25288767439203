import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  ButtonGroup,
  Label,
  Breadcrumb,
  BreadcrumbItem,
  Badge
} from 'reactstrap';
import { connect } from 'react-redux';
import Widget from '../../../components/Widget/Widget';
import { fetchCompanies } from '../../../actions/company';
import { createCustomer, editCustomer } from '../../../actions/customer';
import styles from './CustomerNew.module.scss';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import singletonCleverTapInstance from '../../../analytics/clevertap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class CustomerNew extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
    companies: PropTypes.array, // eslint-disable-line
  };

  static defaultProps = {
    isFetching: false,
    message: null,
    companies: [],
  };

  static meta = {
    title: 'Create a new user',
    description: 'About description',
  };

  constructor(props) {
    super(props);
    this.state = {
      id:'',
      firstName: '',
      lastName: '',
      isActive: null,
      emailId: '',
      phoneNumber: '',
      companyId: '',
      roleId: '',
      isAddMode: true,
      startDate: null,
      endDate: null,
    };
  }

  componentDidMount() {
    if(this.props && this.props.location){
      if(this.props.location.customerDetails){
        let customerDetails = this.props.location.customerDetails;
        this.setState(
          {
            id: customerDetails.id,
            firstName: customerDetails.firstName,
            lastName: customerDetails.lastName,
            isActive: customerDetails.isActive,
            emailId: customerDetails.emailId,
            phoneNumber: customerDetails.phoneNumber,
            companyId: customerDetails.company.id,
            roleId: customerDetails.roleId,
            isAddMode: false,
            startDate: customerDetails.startDate ? new Date(customerDetails.startDate) : null,
            endDate: customerDetails.endDate ? new Date(customerDetails.endDate) : null,
          }
        )
      }
      this.props.dispatch(fetchCompanies());
    }
  }

  changeFirstName = (event) => {
    this.setState({firstName: event.target.value});
  }

  changeLastName = (event) => {
    this.setState({lastName: event.target.value});
  }

  changeEmail = (event) => {
    this.setState({emailId: event.target.value});
  }

  changePhoneNumber = (event) => {
    this.setState({phoneNumber: event.target.value});
  }

  changeCompany = (event) => {
    this.setState({companyId: event.target.value});
  }

  changeRole =  (event) => {
    this.setState({roleId: event.target.value});
  }

  changeStartDate = (value) => {
    this.setState({startDate: value});
  }

  changeEndDate = (value) => {
    this.setState({endDate: value});
  }

  doCreateCustomer = (e) => {
    if(this.state.isAddMode){
      this.props
        .dispatch(
          createCustomer({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            emailId: this.state.emailId.trim(),
            phoneNumber: this.state.phoneNumber,
            companyId: this.state.companyId === '' ? null : this.state.companyId,
            roleId: this.state.roleId === '' ? 1 : this.state.roleId,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          }),
        )
        .then(() => {
          singletonCleverTapInstance.sendInvite(this.state.emailId);
          this.setState({
            id:'',
            firstName: '',
            lastName: '',
            emailId: '',
            phoneNumber: '',
            companyId: '',
            roleId: '',
            isAddMode: true,
            startDate: null,
            endDate: null,
          });
          this.props.errorMessage && (toast.error(this.props.errorMessage))
          this.props.message && (toast.success(this.props.message))
        });
    } else {
      this.props
      .dispatch(
        editCustomer({
          id: this.state.id,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          emailId: this.state.emailId.trim(),
          phoneNumber: this.state.phoneNumber,
          companyId: this.state.companyId === '' ? null : this.state.companyId,
          roleId: this.state.roleId === '' ? 1 : this.state.roleId,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
        })
      ).then((response) => {
        this.setState({ isActive: response.isActive });
        this.props.errorMessage && (toast.error(this.props.errorMessage))
        this.props.message && (toast.success(this.props.message))
      });
    }
    e.preventDefault();
  }

  changeMode = () =>{
    this.setState({
      id:'',
      firstName: '',
      lastName: '',
      emailId: '',
      phoneNumber: '',
      companyId: '',
      isAddMode: true,
      isActive: null,
      startDate: null,
      endDate: null,
    });
  }

  render() {
    return (
      <div className={styles.root}>
         <Breadcrumb>
          <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
          <BreadcrumbItem active>User</BreadcrumbItem>
        </Breadcrumb>
        <h1>{this.state.isAddMode ? 'Create a new User' : 'Edit User'}</h1>
        {this.state.isAddMode ?
          '' : 
          <Row>
            <Col sm={6}>
              <div className="pull-right mt-n-xs">
                <div className={`btn btn-sm btn-default `+ styles.modeButton}  onClick={this.changeMode}>
                  Or, Create new
                </div>
              </div>
            </Col>
          </Row>
        }
        <Row>
          <Col sm={6}>
            <Widget>
              <Form onSubmit={this.doCreateCustomer}>
                <FormGroup>
                  <Label for="input-firstname">First Name</Label>
                  <Input
                    id="input-firstname"
                    type="text"
                    placeholder="First Name"
                    value={this.state.firstName?this.state.firstName:''}
                    required
                    onChange={this.changeFirstName}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="input-lastname">Last Name</Label>
                  <Input
                    id="input-lastname"
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    value={this.state.lastName?this.state.lastName:''}
                    required
                    onChange={this.changeLastName}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="input-emailId">Email</Label>
                  <Input
                    id="input-emailId"
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={this.state.emailId}
                    required
                    onChange={this.changeEmail}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="input-phone">Phonenumber</Label>
                  <Input
                    id="input-phone"
                    type="text"
                    className="form-control"
                    placeholder="+4712345678"
                    value={this.state.phoneNumber?this.state.phoneNumber:''}
                    required
                    onChange={this.changePhoneNumber}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Company</Label>
                  <br />
                  <select required className="form-control" name="selectCompany" value={this.state.companyId} onChange={this.changeCompany}>
                    <option value="">Select company</option>
                    {this.props.companies.map(function (company) {
                      return <option key={company.id} value={company.id}>{company.name}</option>;
                    })}
                  </select>
                </FormGroup>
                <FormGroup>
                  <Label>Role</Label>
                  <br />
                  <select required className="form-control" name="selectRole" value={this.state.roleId} onChange={this.changeRole}>
                    <option value="">Select role</option>
                    <option key="employee" value={1}>{"Employee"}</option>;
                    <option key="admin" value={2}>{"Company admin"}</option>;
                    <option key="superadmin" value={3}>{"Aurora admin"}</option>;
                  </select>
                </FormGroup>
                <FormGroup>
                  <Label>Start Date</Label>
                  <br></br>
                  <DatePicker required className='form-control'
                    selected={this.state.startDate}
                    onChange={this.changeStartDate}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>End Date</Label>
                  <br></br>
                  <DatePicker className='form-control'
                    selected={this.state.endDate}
                    onChange={this.changeEndDate}
                  />
                </FormGroup>
                <div>
                  {!this.state.isAddMode ?
                    <div>
                      <Label>Status</Label> <br/>
                      <Label>{this.state.isActive?<Badge color="success">Active</Badge> : <Badge color="danger">Inactive</Badge>}</Label>
                    </div>
                    : ''}
                </div>
                <div className="d-flex justify-content-end">
                  <ButtonGroup>
                    <Button color="info" type="submit" disabled={this.props.isFetching}>
                      {this.props.isFetching ? 'Saving...' : this.state.isAddMode ?'Create' : 'Save'}
                    </Button>
                  </ButtonGroup>
                </div>
              </Form>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.customer.isFetching,
    message: state.customer.message,
    errorMessage: state.customer.errorMessage,
    companies: state.company.companies,
  };
}

export default connect(mapStateToProps)(CustomerNew);
