import {
  CREATE_COMPANY_INITIAL,
  CREATE_COMPANY_REQUEST,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAILURE,
  EDIT_COMPANY_INITIAL,
  EDIT_COMPANY_REQUEST,
  EDIT_COMPANY_SUCCESS,
  EDIT_COMPANY_FAILURE,
  DELETE_COMPANY_INITIAL,
  DELETE_COMPANY_REQUEST,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAILURE,
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
  REQUEST_UPLOAD_USERS,
  REQUEST_UPLOAD_USERS_SUCCESS,
  REQUEST_UPLOAD_USERS_FAILURE
} from '../actions/company';

export default function COMPANY(
  state = {
    isFetching: false,
    companies: [],
  },
  action,
) {
  switch (action.type) {
    case CREATE_COMPANY_INITIAL:
      return Object.assign({}, state, {
        isFetching: false,
        message: null,
      });
    case CREATE_COMPANY_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case CREATE_COMPANY_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        message: 'COMPANY created successfully',
      });
    case CREATE_COMPANY_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        message:
          'Something went wrong while COMPANY creation. Please try again.',
      });
    case EDIT_COMPANY_INITIAL:
      return Object.assign({}, state, {
        isFetching: false,
        message: null,
      });
    case EDIT_COMPANY_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case EDIT_COMPANY_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        message: 'COMPANY details edited successfully',
      });
    case EDIT_COMPANY_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        message:
          'Something went wrong while COMPANY updation. Please try again.',
      });
    case DELETE_COMPANY_INITIAL:
      return Object.assign({}, state, {
        isFetching: false,
        message: null,
        errorMessage: null,
      });
    case DELETE_COMPANY_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case DELETE_COMPANY_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        companies: state.companies.filter(c => c.id !== action.payload.id),
        message: action.payload.message,
        errorMessage: null,
      });
    case DELETE_COMPANY_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.message,
        message: null,
      });
    case FETCH_COMPANIES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case FETCH_COMPANIES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        companies: action.companies
      });
    case FETCH_COMPANIES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        message: 'Something wrong happened. Please come back later',
      });
    case REQUEST_UPLOAD_USERS:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case REQUEST_UPLOAD_USERS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        message: 'Upload Successful',
        errorMessage: null
      });  
    case REQUEST_UPLOAD_USERS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.message,
        message: null,
      });  
    default:
      return state;
  }
}
