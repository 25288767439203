import React from 'react';
import { Switch, Route, withRouter } from 'react-router';

import CustomerList from './list/CustomerList';
import CustomerNew from './new/CustomerNew';
import CustomerDetails from './details/CustomerDetails';

class Customers extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/app/users" exact component={CustomerList} />
        <Route path="/app/user/new" exact component={CustomerNew} />
        <Route path="/app/user" exact component={CustomerDetails} />
      </Switch>
    );
  }
}

export default withRouter(Customers);
