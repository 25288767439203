import {
  CREATE_CUSTOMER_INITIAL,
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAILURE,
  EDIT_CUSTOMER_INITIAL,
  EDIT_CUSTOMER_REQUEST,
  EDIT_CUSTOMER_SUCCESS,
  EDIT_CUSTOMER_FAILURE,
  DELETE_CUSTOMER_INITIAL,
  DELETE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAILURE,
  FETCH_CUSTOMERS_REQUEST,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_FAILURE,
  CREATE_INVITE_FAILURE,
  CREATE_INVITE_SUCCESS
} from '../actions/customer';

export default function CUSTOMER(
  state = {
    isFetching: false,
    customers: [],
  },
  action,
) {
  switch (action.type) {
    case CREATE_CUSTOMER_INITIAL:
      return Object.assign({}, state, {
        isFetching: false,
        message: null,
        errorMessage: null,
      });
    case CREATE_CUSTOMER_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case CREATE_CUSTOMER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        message: 'CUSTOMER created successfully',
        errorMessage: null,
      });
    case CREATE_CUSTOMER_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage:'Something went wrong while CUSTOMER creation. Please try again.',
        message: null,
      });
    case EDIT_CUSTOMER_INITIAL:
      return Object.assign({}, state, {
        isFetching: false,
        message: null,
        errorMessage: null,
      });
    case EDIT_CUSTOMER_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case EDIT_CUSTOMER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        message: 'CUSTOMER details edited successfully',
        errorMessage: null,
      });
    case EDIT_CUSTOMER_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage:'Something went wrong while CUSTOMER updation. Please try again.',
        message: null,
      });
    case DELETE_CUSTOMER_INITIAL:
      return Object.assign({}, state, {
        isFetching: false,
        message: null,
        errorMessage: null,
      });
    case DELETE_CUSTOMER_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case DELETE_CUSTOMER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        customers: state.customers.filter(c => c.id !== action.payload.id),
        message: action.payload.message,
        errorMessage: null,
      });
    case DELETE_CUSTOMER_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.message,
        message: null,
      });
    case FETCH_CUSTOMERS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case FETCH_CUSTOMERS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        customers: action.customers
      });
    case FETCH_CUSTOMERS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: 'Something wrong happened. Please come back later',
        message: null
      });
    case CREATE_INVITE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: 'Invitation request not sent',
        message: null
      });
    case CREATE_INVITE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        message: action.message,
        errorMessage: null
      });
    default:
      return state;
  }
}
