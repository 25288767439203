import { combineReducers } from 'redux';
import auth from './auth';
import runtime from './runtime';
import navigation from './navigation';
import company from './company';
import customer from './customer';

export default combineReducers({
  auth,
  runtime,
  navigation,
  company,
  customer
});
