import React from 'react';


class Register extends React.Component {
  render() {
    return (
      <div>register</div>
    );
  }
}

export default Register;
