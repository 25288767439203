import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  ButtonGroup,
  Alert,
  Label,
  Breadcrumb,
  BreadcrumbItem,
  Badge,
} from 'reactstrap';
import { connect } from 'react-redux';

import Widget from '../../../components/Widget/Widget';
import DatePicker from "react-datepicker";
import { createCompany, editCompany } from '../../../actions/company';
import styles from './CompanyNew.module.scss';
import "react-datepicker/dist/react-datepicker.css";

class CompanyNew extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create a new company',
    description: 'About description',
  };

  constructor(props) {
    super(props);
    this.state = {
      id:'',
      name: '',
      emailId: '',
      contactNumber: '',
      companyDescription: '',
      establishmentDate: null,
      endDate: null,
      subscriptionType: '',
      isAddMode: true,
      isActive: null,
      isAnnualHealthCheck: false,
      companyCode: ''
    };
  }

  componentDidMount() {
    if(this.props && this.props.location && this.props.location.companyDetails){
      let companyDetails = this.props.location.companyDetails;
      this.setState(
        {
          id: companyDetails.id,
          name: companyDetails.name,
          emailId: companyDetails.emailId,
          contactNumber: companyDetails.contactNumber,
          companyDescription: companyDetails.companyDescription,
          establishmentDate: companyDetails.establishmentDate ? new Date(companyDetails.establishmentDate) : null,
          endDate: companyDetails.endDate ? new Date(companyDetails.endDate) : null,
          subscriptionType: companyDetails.subscriptionType,
          isAddMode: false,
          isActive: companyDetails.isActive,
          isAnnualHealthCheck: companyDetails.isAnnualHealthCheck,
          companyCode: companyDetails.companyCode
        }
      )
    }
  }

  changeName = (event) => {
    this.setState({name: event.target.value});
  }

  changeEmail = (event) => {
    this.setState({emailId: event.target.value});
  }

  changeContact = (event) => {
    this.setState({contactNumber: event.target.value});
  }

  changeDescription = (event) => {
    this.setState({companyDescription: event.target.value});
  }

  changeEstablishmentDate = (value) => {
    this.setState({establishmentDate: value});
  }

  changeEndDate = (value) => {
    this.setState({endDate: value});
  }

  changeSubscriptionType = (event) => {
    this.setState({subscriptionType: event.target.value});
  }

  changeIsAnnualhealthCheck = () => {
    this.setState({isAnnualHealthCheck: !this.state.isAnnualHealthCheck});
  }

  changeCompanyCode = (event) => {
    this.setState({companyCode: event.target.value});
  }

  doCreateCompany = (e) => {
    if(this.state.isAddMode){
      this.props
        .dispatch(
          createCompany({
            name: this.state.name,
            emailId: this.state.emailId,
            contactNumber: this.state.contactNumber,
            companyDescription: this.state.companyDescription,
            establishmentDate: this.state.establishmentDate,
            endDate: this.state.endDate,
            subscriptionType: this.state.subscriptionType,
            isAnnualHealthCheck: this.state.isAnnualHealthCheck,
            companyCode: this.state.companyCode
          }),
        )
        .then(() =>
          this.setState({
            id:'',
            name: '',
            emailId: '',
            contactNumber: '',
            companyDescription: '',
            establishmentDate: null,
            endDate: null,
            subscriptionType: '',
            isAnnualHealthCheck: false,
            companyCode: ''
          }),
        );
    } else {
      this.props
        .dispatch(
          editCompany({
            id: this.state.id,
            name: this.state.name,
            emailId: this.state.emailId,
            contactNumber: this.state.contactNumber,
            companyDescription: this.state.companyDescription,
            establishmentDate: this.state.establishmentDate,
            endDate: this.state.endDate,
            subscriptionType: this.state.subscriptionType,
            isAnnualHealthCheck: this.state.isAnnualHealthCheck,
            companyCode: this.state.companyCode
          }),
      ).then((response) => {
        this.setState({ isActive: response.isActive });
        });
    }
    e.preventDefault();
  }

  changeMode = () =>{
    this.setState({
      id:'',
      name: '',
      emailId: '',
      contactNumber: '',
      companyDescription: '',
      establishmentDate: null,
      endDate: null,
      subscriptionType: '',
      isAddMode: true,
      isActive: null,
      isAnnualHealthCheck: false,
      companyCode: ''
    });
  }

  render() {
    return (
      <div className={styles.root}>
         <Breadcrumb>
          <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
          <BreadcrumbItem active>New Company</BreadcrumbItem>
        </Breadcrumb>
        <h1>{this.state.isAddMode ? 'Create a new Company' : 'Edit Company'}</h1>
        {this.state.isAddMode ?
          '' : 
          <Row>
            <Col sm={6}>
              <div className="pull-right mt-n-xs">
                <div className={`btn btn-sm btn-default `+ styles.modeButton}  onClick={this.changeMode}>
                  Or, Create new
                </div>
              </div>
            </Col>
          </Row>
        }
        <Row>
          <Col sm={6}>
            <Widget>
              <Form onSubmit={this.doCreateCompany}>
                {this.props.message && (
                  <Alert className="alert-sm" bsstyle="warning">
                    {this.props.message}
                  </Alert>
                )}
                <FormGroup>
                  <Label for="input-name">Company name</Label>
                  <Input
                    id="input-name"
                    type="text"
                    placeholder="Name"
                    value={this.state.name}
                    required
                    onChange={this.changeName}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="input-emailId">Email contact person</Label>
                  <Input
                    id="input-emailId"
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    value={this.state.emailId}
                    required
                    onChange={this.changeEmail}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="input-phone">Phone number</Label>
                  <Input
                    id="input-phone"
                    className="form-control"
                    placeholder="+4712345678"
                    type="text"
                    value={this.state.contactNumber}
                    required
                    onChange={this.changeContact}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="input-companyDescription">Company Description</Label>
                  <textarea
                    id="input-companyDescription"
                    className="form-control"
                    placeholder="Description of company"
                    value={this.state.companyDescription}
                    required
                    onChange={this.changeDescription}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="selectSubscriptionType">Subscription Type</Label>
                  <br></br>
                  <select required className='form-control' name="selectSubscriptionType" value={this.state.subscriptionType} onChange={this.changeSubscriptionType}>
                    <option value="">Select Subscription Type</option>
                    <option key="basis" value={'basis'}>{"Basis"}</option>;
                    <option key="standard" value={'standard'}>{"Standard"}</option>;
                    <option key="pluss" value={'pluss'}>{"Pluss"}</option>;
                    <option key="test" value={'test'}>{"Test"}</option>;
                    <option key="basis+" value={'basis+'}>{"Basis+"}</option>;
                    <option key="basis2" value={'basis2'}>{"Basis2"}</option>;
                    <option key="basis-light" value={'basis-light'}>{"Basis light"}</option>;
                  </select>
                </FormGroup>
                {/* <Label>Additional Services</Label> */}
                <FormGroup
                  check
                >
                <Label for="annual-check" check>
                  <Input id="annual-check" type="checkbox"
                    checked={this.state.isAnnualHealthCheck}
                    onChange={this.changeIsAnnualhealthCheck}
                  />
                    Annual Health check
                  </Label>
                </FormGroup>
                <FormGroup>
                  <br></br>
                  <Label for="input-code">Company code</Label>
                  <Input
                    id="input-code"
                    type="text"
                    placeholder="Company code"
                    value={this.state.companyCode}
                    required={this.state.isAnnualHealthCheck}
                    onChange={this.changeCompanyCode}
                    />
                </FormGroup>
                <FormGroup>
                  <Label>Start Date</Label>
                  <br></br>
                  <DatePicker required className='form-control'
                    selected={this.state.establishmentDate}
                    onChange={this.changeEstablishmentDate} //only when value has changed
                  />
                </FormGroup>
                <FormGroup>
                  <Label>End Date</Label>
                  <br></br>
                  <DatePicker className='form-control'
                    selected={this.state.endDate}
                    onChange={this.changeEndDate} //only when value has changed
                  />
                </FormGroup>
                <div>
                  {!this.state.isAddMode ?
                    <div>
                      <Label>Status</Label> <br/>
                      <Label>{this.state.isActive?<Badge color="success">Active</Badge> : <Badge color="danger">Inactive</Badge>}</Label>
                    </div>
                    : ''}
                </div>
                <div className="d-flex justify-content-end">
                  <ButtonGroup>
                    <Button color="info" type="submit" disabled={this.props.isFetching}>
                      {this.props.isFetching ? 'Saving...' : this.state.isAddMode ?'Create' : 'Save'}
                    </Button>
                  </ButtonGroup>
                </div>
              </Form>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.company.isFetching,
    message: state.company.message,
  };
}

export default connect(mapStateToProps)(CompanyNew);
