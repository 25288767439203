import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Table,
  Breadcrumb,
  BreadcrumbItem,
  Badge,
  Input,
} from 'reactstrap';
import s from './CompanyList.module.scss';
import Widget from '../../../components/Widget/Widget';
import { fetchCompanies ,deleteCompany, uploadUsers } from '../../../actions/company';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CompanyList extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    companies: PropTypes.array, // eslint-disable-line
    isFetching: PropTypes.bool,
    message: PropTypes.string,
    errorMessage: PropTypes.string
  };

  static defaultProps = {
    isFetching: false,
    companies: [],
    message: null,
    errorMessage:null
  };

  static meta = {
    title: 'Companies list',
    description: 'About description',
  };

  state = {
    searchQuery: '',
    statusQuery: '',
    subscriptionQuery: ''
  }

  componentDidMount() {
    this.props.dispatch(fetchCompanies());
  }

  formatDate = (str) => {
    return str.replace(/,.*$/,"")
  }

  deleteCompanyData = (companyId) => {
    this.props
    .dispatch(
      deleteCompany(companyId),
    ).then(()=>{
      this.props.errorMessage && (toast.error(this.props.errorMessage))
      this.props.message && (toast.success(this.props.message))
      
    });
  }

  uploadUsersCSV = (event,companyId) => {
    event.preventDefault();
    var file = event.target.files[0];
    event.target.value = '';
    this.props
    .dispatch(
      uploadUsers(companyId,file),
    ).then(()=>{
      this.props.errorMessage && (toast.error(this.props.errorMessage));
      this.props.message && (toast.success(this.props.message));
    });
  }

  searchCompany = (e) => {
    this.setState({searchQuery: e.target.value});
    e.preventDefault();
  }

  filterByStatus = (e) => {
    this.setState({statusQuery: e.target.value});
  }

  filterBySubscription = (e) => {
    this.setState({subscriptionQuery: e.target.value});
  }

  render() {
    return (
      <div className={s.root}>
        <Breadcrumb>
          <BreadcrumbItem>YOU ARE HERE</BreadcrumbItem>
          <BreadcrumbItem active>Companies</BreadcrumbItem>
        </Breadcrumb>
        <h1>Companies</h1>
        <Widget
          className="pb-0"
          title={
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-between align-items-center">
                <Input className={s.searchUser} type="search" onChange={this.searchCompany} name="search" id="customerSearch" placeholder="search by company"/>
                <select className={s.selectCompany} name="selectStatus" value={this.state.statusQuery} onChange={this.filterByStatus}>
                  <option value="">Select status</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
                <select className={s.selectCompany} name="selectSubscription" value={this.state.subscriptionQuery} onChange={this.filterBySubscription}>
                  <option value="">Select subscription</option>
                  <option value="standard">Standard</option>
                  <option value="basis">Basis</option>
                  <option value="pluss">Pluss</option>
                  <option value="basis+">Basis+</option>
                  <option value="basis+">Basis2</option>
                  <option value="basis-light">Basis light</option>
                </select>
              </div>
              <div>
                <Link to={{ pathname: '/app/company/new' }}  className="btn btn-sm btn-info">
                  Create new
                </Link>
              </div>
            </div>
          }
        >
          <div className="widget-table-overflow">
            <Table striped>
              <thead>
              <tr>
                <th>Name</th>
                <th>No. of users</th>
                <th>Email contact person</th>
                <th>Status</th>
                <th>Subscription Type</th>
                <th>Upload Users</th>
                <th>Options</th>
              </tr>
              </thead>
              <tbody>
              { this.props.companies &&
                  this.props.companies.filter(company => company.name.toLowerCase().includes(this.state.searchQuery.toLowerCase()) 
                    && company.subscriptionType.includes(this.state.subscriptionQuery)
                    && company.isActive.toString().toLowerCase().includes(this.state.statusQuery.toLowerCase())
                  )
                .map(company => (
                <tr key={company.id}>
                  <td>{company.name?company.name.slice(0, 10)+'...':'-'}</td>
                  <td>{company.employeeCount?company.employeeCount:'-'}</td>
                  <td>{company.emailId?company.emailId:'-'}</td>
                  <td>{company.isActive?<Badge color="success">Active</Badge> : <Badge color="danger">Inactive</Badge>}</td>  
                  <td>{company.subscriptionType?company.subscriptionType:'-'}</td>
                  <td>
                    <input type="file" accept=".csv" onChange={(e) => this.uploadUsersCSV(e,company.id)}/>
                  </td>
                  <td>
                    <Link to={{ pathname: '/app/company/new', companyDetails: company }} className="btn btn-sm btn-inverse">
                      Edit
                    </Link>
                    <div className="btn btn-sm btn-danger" onClick={() => { this.deleteCompanyData(company.id)}}>
                      <i className="glyphicon glyphicon-remove" />
                    </div>
                  </td>
                </tr>
              ))}
              {this.props.isFetching && (
                <tr>
                  <td colSpan="100">Loading...</td>
                </tr>
              )}
              </tbody>
            </Table>
          </div>
        </Widget>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.company.isFetching,
    companies: state.company.companies,
    message: state.company.message,
    errorMessage: state.company.errorMessage
  };
}

export default connect(mapStateToProps)(CompanyList);
