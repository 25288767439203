import clevertap from 'clevertap-web-sdk';

class Clevertap {
    clevertapProjectId = process.env.REACT_APP_CLEVERTAP_PROJECT_ID;

    constructor() {
        clevertap.privacy.push({optOut: false});
        clevertap.privacy.push({useIP: false});
        clevertap.init(this.clevertapProjectId);
    }

    sendInvite(emailId){
        clevertap.event.push("Invitation sent",{
            email: emailId
        });
    }

}
  
  const singletonCleverTapInstance = new Clevertap();
  
  Object.freeze(singletonCleverTapInstance); //immutable

  export default singletonCleverTapInstance;
